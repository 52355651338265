/** @format */
/* eslint-disable react/prop-types */

import { PrepareInspectionContext } from 'apps/wms/stores/PrepareInspectionStore'
import { Input, Select } from 'components/form'
import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import _, { toInteger } from 'lodash'
import { Button, FlexView, Icon, LoadingOverlay } from 'components/common'
import { INSPECTION_STAGES, SOURCE_TYPES_ENTRANCE } from 'apps/wms/utils/enum'
import { toast } from 'react-toastify'

const Filters = ({ inspectionType, inspections, newContent, materialList, selectMaterial, selectLevel, newData }) => {
	const { t } = useTranslation()
	const {
		ownerships,
		getOwnershipByEnduser,
		endUsers,
		getMaterialsByEU,
		getAvailableQty,
		getContentByOwner,
		getOrdersList,
		enduser,
		setEnduser,
		ownership,
		setOwnership,
		material,
		setMaterial,
		content,
		setContent,
		cOrder,
		setCOrder,
		cItem,
		setCItem,
		sOrder,
		setSOrder,
		sItem,
		setSItem,
		rack,
		setRack,
		quantity,
		setQuantity,
		sourceMaterial,
		setSourceMaterial,
		targetMaterial,
		setTargetMaterial,
		qntAvailable,
		setQntAvailable,
		salesOrderList,
		setSalesOrderList,
		filteredSalesItems,
		setFilteredSalesItems,
		isMatRequisition,
		isArrival,
		isReturn,
		selectedReport,
		contentFilters,
		setContentFilters,
		ownershipList,
		setOwnershipList,
		sourceType,
		setSourceType
	} = useContext(PrepareInspectionContext)

	const [enduserList, setEnduserList] = useState([])

	const [materials, setMaterials] = useState([])
	const [customerOrderList, setCustomerOrderList] = useState([])
	const [customerItemList, setCustomerItemList] = useState([])
	const [filteredPoItems, setFilteredPoItems] = useState([])
	const [salesItemList, setSalesItemList] = useState([])
	const [sourceMatList, setSourceMatList] = useState([])
	const [targetMatList, setTargetMatList] = useState([])
	const [shelfList, setShelfList] = useState([])
	const [contentData, setContentData] = useState({
		content: [],
		expected_date: ''
	})

	const [loading, setLoading] = useState(false)
	const [loadingQty, setLoadingQty] = useState(false)

	const noAvailableQuantityStr = t('wms:NoAvailableQuantity')
	const materialStr = t('wms:Material')

	const checkRules = () => {
		let isDisabled = true
		if (selectedReport && isReturn && inspectionType.is_modified_product) {
			selectedReport.contents && selectedReport.contents.find(report => report.filters.enduser)
				? (isDisabled = true)
				: (isDisabled = false)
			return isDisabled
		} else if (inspectionType && !inspectionType.is_filter_on_content) {
			contentFilters &&
			(contentFilters.find(filter => filter.enduser) || contentFilters.find(filter => filter.material))
				? (isDisabled = true)
				: (isDisabled = false)
		} else isDisabled = false

		return isDisabled
	}

	const checkButtonRules = () => {
		let isAddButton = true
		isAddButton = isAddButton && !isRigReturn()
		return isAddButton
	}

	const allNull = () => {
		let keys = Object.keys(contentData)
		let isEmpty = true
		_.forEach(keys, k => {
			return (isEmpty = _.some(contentData, n => n[k] === ''))
		})
		return enduser === null && ownership === null && isEmpty
	}

	const resetFields = () => {
		setEnduser(null)
		setOwnership(null)
		let summary =
			inspectionType &&
			inspectionType.config_summary &&
			inspectionType.config_summary.filter(s => s.enabled === true)
		let keys = summary && _.map(summary, s => ({ [s.name.toLowerCase()]: '' }))
		let params = keys && Object.assign({}, ...keys)
		params
			? setContentData({
					...contentData,
					...params,
					content: []
			  })
			: setContentData({
					...contentData,
					name: '',
					content: []
			  })
	}

	const addContent = () => {
		if (isArrival) {
			sourceType === 'CUSTOMER_ITEM' &&
				newContent({
					filters: {
						enduser: enduser,
						material: material,
						customer_order: cOrder,
						customer_item: cItem
					},
					quantity: quantity,
					execution_status: 0
				})
			sourceType === 'CUSTOMER_ITEM' &&
				setContentFilters([
					...contentFilters,
					{
						quantity: quantity,
						enduser: enduser,
						material: material,
						customer_order: cOrder,
						customer_item: cItem
					}
				])
			sourceType === 'SALES_ITEM' &&
				newContent({
					filters: {
						enduser: enduser,
						material: material,
						sales_order: salesOrderList.find(s => s.label === sOrder).label,
						sales_item: filteredSalesItems.find(s => s.label === sItem).label
					},
					quantity: quantity,
					execution_status: 0
				})
			sourceType === 'SALES_ITEM' &&
				setContentFilters([
					...contentFilters,
					{
						quantity: quantity,
						enduser: enduser,
						material: material,
						sales_order: cOrder,
						sales_item: cItem
					}
				])
			sourceType === 'ALL_SOURCES' &&
				newContent({
					filters: {
						enduser: enduser,
						material: material
					},
					quantity: quantity,
					execution_status: 0
				})
			sourceType === 'ALL_SOURCES' &&
				setContentFilters([
					...contentFilters,
					{
						quantity: quantity,
						enduser: enduser,
						material: material
					}
				])
			selectedReport &&
				selectedReport.filters &&
				selectedReport.filters.customer_order &&
				newContent({
					filters: {
						enduser: selectedReport.filters.enduser,
						material: selectedReport.filters.material,
						customer_order: selectedReport.filters.customer_order,
						customer_item: selectedReport.filters.customer_item
					},
					quantity: quantity,
					execution_status: 0
				})
			selectedReport &&
				selectedReport.filters &&
				selectedReport.filters.customer_order &&
				setContentFilters([
					...contentFilters,
					{
						quantity: quantity,
						enduser: selectedReport.filters.enduser,
						material: selectedReport.filters.material,
						customer_order: selectedReport.filters.customer_order,
						customer_item: selectedReport.filters.customer_item
					}
				])
			selectedReport &&
				selectedReport.filters &&
				selectedReport.filters.sales_order &&
				newContent({
					filters: {
						enduser: selectedReport.filters.enduser,
						material: selectedReport.filters.material,
						sales_order: selectedReport.filters.sales_order,
						sales_item: selectedReport.filters.sales_item
					},
					quantity: quantity,
					execution_status: 0
				})
			selectedReport &&
				selectedReport.filters &&
				selectedReport.filters.sales_order &&
				setContentFilters([
					...contentFilters,
					{
						quantity: quantity,
						enduser: selectedReport.filters.enduser,
						material: selectedReport.filters.material,
						sales_order: selectedReport.filters.sales_order,
						sales_item: selectedReport.filters.sales_item
					}
				])
			setQntAvailable(qntAvailable - quantity)
			setQuantity(0)
		}
		if (isMatRequisition) {
			let rackName = _.find(shelfList, lv => lv.id === rack)
			newContent({
				filters: {
					source_ownership: ownership,
					enduser: enduser,
					material: material,
					level: rack,
					levelname: rackName && rackName.fullname
				},
				quantity: toInteger(quantity),
				execution_status: 0
			})
			setContentFilters([
				...contentFilters,
				{
					source_ownership: ownership,
					enduser: enduser,
					material: material,
					level: rack,
					quantity: toInteger(quantity)
				}
			])
			setQntAvailable(qntAvailable - quantity)
			setQuantity(0)
		}
		if (inspectionType.is_modified_product && isReturn) {
			newContent({
				filters: {
					enduser: enduser,
					material: targetMaterial,
					material_source: sourceMaterial,
					target_ownership: ownership
				},
				quantity: quantity,
				execution_status: 0
			})
			setContentFilters([
				...contentFilters,
				{
					enduser: enduser,
					material: targetMaterial,
					material_source: sourceMaterial,
					target_ownership: ownership,
					quantity: quantity
				}
			])
			setQntAvailable(qntAvailable - quantity)
			setQuantity(0)
		}
	}

	const checkFilters = () => {
		let isDisabled = true
		const isCustomer = sourceType === 'CUSTOMER_ITEM'
		const isSales = sourceType === 'SALES_ITEM'
		if (!inspectionType) isDisabled = true
		if (isMatRequisition) {
			isDisabled =
				isDisabled &&
				(enduser === null || material === null || ownership === null || rack === null || quantity <= 0)
		} else if (isArrival) {
			isDisabled =
				isDisabled &&
				((isCustomer && (cOrder === null || cItem === null)) ||
					(isSales && (sOrder === null || sItem === null)) ||
					enduser === null ||
					material === null ||
					quantity <= 0)
		} else if (isReturn && inspectionType.is_modified_product) {
			isDisabled =
				isDisabled && (sourceMaterial === null || targetMaterial === null || enduser === null || quantity <= 0)
		}

		return isDisabled
	}

	const sumByProp = (data, prop) => {
		if (!data || data.length === 0) {
			return 0
		}

		if (cOrder && cItem) {
			const filteredData =
				data.filter(
					val =>
						val.filters &&
						val.filters.customer_order &&
						val.customer_item &&
						val.filters.customer_order === cOrder &&
						val.filters.customer_item === cItem
				) || []

			return filteredData.reduce((acc, curr) => {
				return acc + curr[prop]
			}, 0)
		} else if (sOrder && sItem) {
			const filteredData =
				data.filter(
					val =>
						val.filters &&
						val.filters.sales_order &&
						val.filters.sales_item &&
						val.filters.sales_order === sOrder &&
						val.filters.sales_item === sItem
				) || []

			return filteredData.reduce((acc, curr) => {
				return acc + curr[prop]
			}, 0)
		}

		return 0
	}

	const defineStates = () => {
		setEnduser(selectedReport.filters.enduser || null)
		setCOrder(selectedReport.filters.customer_order || null)
		setCItem(selectedReport.filters.customer_item || null)
		setSOrder(selectedReport.filters.sales_order || null)
		setSItem(selectedReport.filters.sales_item || null)
		setMaterial(selectedReport.filters.material || null)
	}

	useEffect(() => {
		if (selectedReport && selectedReport.filters && isArrival) {
			defineStates()
			const isCustomers =
				selectedReport.filters.customer_order != null && selectedReport.filters.customer_item != null
			const isSales = selectedReport.filters.sales_order != null && selectedReport.filters.sales_item != null
			const isAllSources =
				selectedReport.filters.customer_order === null &&
				selectedReport.filters.customer_item === null &&
				selectedReport.filters.sales_order === null &&
				selectedReport.filters.sales_item === null
			const handleWarnMessage = () => {
				return toast.warn(noAvailableQuantityStr)
			}
			if (selectedReport.filters.enduser && selectedReport.filters.material) {
				setLoadingQty(true)
				getAvailableQty(
					selectedReport.filters.enduser,
					selectedReport.filters.material || sourceMaterial,
					selectedReport.filters.customer_order || selectedReport.filters.sales_order,
					selectedReport.filters.customer_item || selectedReport.filters.sales_item,
					isSales,
					isCustomers,
					isAllSources
				)
					.then(quantity => {
						if (quantity === 0) handleWarnMessage()
						setQntAvailable(quantity)
					})
					.catch(e => console.error(e))
					.finally(() => setLoadingQty(false))
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedReport, isArrival])

	useEffect(() => {
		if (!inspectionType) return
		if (!inspectionType.config_filters.find(f => f.name === 'Enduser')) setEnduserList([])
		else setEnduserList(endUsers ? endUsers : [])
	}, [endUsers, inspectionType])

	const resetStates = () => {
		setOwnershipList([])
		setCustomerOrderList([])
		setCustomerItemList([])
		setMaterials([])
		materialList([])
	}

	const defineConstants = (unifiedMaterials, matList, owners, ownerships) => {
		materialList(unifiedMaterials)
		setSourceMatList(unifiedMaterials)
		setTargetMatList(unifiedMaterials)
		setMaterials(matList)
		setOwnershipList((owners && owners.length && owners) || ownerships)
	}

	useEffect(() => {
		if (ownership) {
			const hydrate = async () => {
				try {
					setLoading(true)
					const contents = await getContentByOwner(ownership, 0).finally(() => setLoading(false))
					setContent(contents)
				} catch (e) {
					console.error(e)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownership])

	useEffect(() => {
		if (material && enduser && sourceType) {
			const hydrate = async () => {
				try {
					setLoading(true)
					const isCustomer = sourceType === 'CUSTOMER_ITEM'
					const isSales = sourceType === 'SALES_ITEM'
					const list = await getOrdersList(enduser, material, isCustomer, isSales).finally(() => setLoading(false))
					if (isCustomer) {
						setCustomerOrderList(list[0] || [])
						setCustomerItemList(list[1] || [])
					} else if (isSales) {
						setSalesOrderList(list[0] || [])
						setSalesItemList(list[1] || [])
					}
				} catch (e) {
					console.error(e)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [material, enduser, sourceType])

	useEffect(() => {
		if (!inspectionType) {
			resetStates()
		} else {
			const hydrate = async () => {
				try {
					setLoading(true)
					const owners = (enduser && (await getOwnershipByEnduser(enduser).finally(() => setLoading(false)))) || []
					const matList = (enduser && (await getMaterialsByEU(enduser).finally(() => setLoading(false)))) || []
					const unifiedMaterials = _.uniqBy(matList, 'id').sort((a, b) => a.id - b.id)
					defineConstants(unifiedMaterials, matList, owners, ownerships)
				} catch (e) {
					console.error(e)
				} finally {
					setLoading(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enduser, inspectionType, inspections, ownerships, ownership])

	useEffect(() => {
		if (!inspectionType) {
			setFilteredPoItems([])
			setFilteredSalesItems([])
		} else if (isArrival && cOrder) {
			setCItem(null)
			setFilteredPoItems(customerItemList[cOrder])
		} else if (isArrival && sOrder) {
			setSItem(null)
			cOrder && setFilteredPoItems(customerItemList[cOrder])
			sOrder && setFilteredSalesItems(salesItemList[sOrder])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cOrder, customerItemList, inspectionType, inspections, sOrder, salesItemList])

	useEffect(() => {
		setEnduser(null)
		setOwnership(null)
		setMaterial(null)
		setContent(null)
		setCOrder(null)
		setCItem(null)
		setSOrder(null)
		setSItem(null)
		setRack(null)
		setSourceMaterial(null)
		setTargetMaterial(null)
		setSourceType(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspectionType])

	useEffect(() => {
		if (content && content.length) {
			let materials = content
				.filter(item => item.material.enduserid === enduser)
				.map(ct => ({
					enduserid: ct.material.enduserid,
					id: ct.material.id,
					formatted: ct.material.formatted
				}))
				.sort((a, b) => a.id - b.id)
			let uniqMaterial = _.uniqBy(materials, 'id')
			materialList(uniqMaterial)
			setMaterials(uniqMaterial)
			let aux = content.map(item => ({
				id: item.level.id,
				fullname: item.level.fullname,
				creationavg: item.creationavg,
				itemcount: item.itemcount
			}))
			aux.sort((a, b) => a.fullname - b.fullname)
			let list = content
				.filter(item => item.material.id === material)
				.map(item => ({
					id: item.level.id,
					fullname: item.level.fullname,
					creationavg: item.creationavg,
					itemcount: item.itemcount
				}))
			setShelfList(list)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [material, content])

	useEffect(() => {
		if (rack && shelfList.length) {
			let selectedRack = shelfList.find(r => r.id === rack)
			let decrease = 0
			let qty = 0
			_.forEach(contentFilters, content => {
				return content.ownership === ownership && content.material === material && content.level === rack
					? (decrease += content.quantity)
					: qty
			})
			decrease
				? selectedRack && setQntAvailable(selectedRack.itemcount - decrease)
				: selectedRack && setQntAvailable(selectedRack.itemcount)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rack, cItem, sItem, material, ownership])

	useEffect(() => {
		const isCustomer = sourceType === 'CUSTOMER_ITEM'
		const isSales = sourceType === 'SALES_ITEM'
		const isAllSources = sourceType === 'ALL_SOURCES'
		const hasCustomerOrderAndItem = cOrder != null && cItem != null
		const hasSalesOrderAndItem = sOrder != null && sItem != null
		setQuantity(0)
		setQntAvailable(0)
		if ((hasCustomerOrderAndItem || hasSalesOrderAndItem) && (material != null || sourceMaterial != null)) {
			setLoadingQty(true)
			getAvailableQty(
				enduser,
				material || sourceMaterial,
				cOrder || sOrder || null,
				cItem || sItem || null,
				isSales,
				isCustomer,
				isAllSources
			)
				.then(quantity => {
					if (quantity === 0) toast.warn(t('wms:NoAvailableQuantity'))
					setQntAvailable(quantity)
				})
				.catch(e => console.error(e))
				.finally(() => setLoadingQty(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cItem, cOrder, enduser, material, sItem, sOrder, sourceMaterial, sourceType, t])

	const isRigReturn = () => {
		let isRigReturn = true
		isRigReturn =
			isRigReturn &&
			inspectionType &&
			inspectionType.stage === INSPECTION_STAGES.code.RETURN &&
			!inspectionType.is_modified_product

		return isRigReturn
	}

	return (
		<FlexView width='100%'>
			<LoadingOverlay visible={loading} borderRadius='card' />
			<FlexView width='100%' margin='0' padding='0' position='relative'>
				{isArrival && (
					<Select
						label={t('wms:TypeOfSource')}
						placeholder={t('wms:TypeOfSource')}
						value={sourceType}
						options={SOURCE_TYPES_ENTRANCE.map(source => ({
							value: source.value,
							label: t(source.label)
						}))}
						onChange={value => {
							setSourceType(value)
						}}
						width='100%'
						inline={false}
						margin='0'
						disabled={checkRules()}
						data-cy='select-source-type'
					/>
				)}
				<Select
					label={t('wms:EndUser')}
					placeholder={t('wms:EndUser')}
					value={
						isReturn &&
						inspectionType &&
						!inspectionType.is_modified_product &&
						selectedReport &&
						selectedReport.filters &&
						selectedReport.filters.enduser
							? selectedReport.filters.enduser
							: enduser
					}
					options={enduserList.map(eu => ({ value: eu.id, label: eu.name }))}
					onChange={value => {
						setEnduser(value)
					}}
					width='100%'
					margin='8px 0 0 0'
					disabled={
						checkRules() ||
						(isReturn &&
							inspectionType &&
							!inspectionType.is_modified_product &&
							selectedReport &&
							selectedReport.filters &&
							selectedReport.filters.enduser)
					}
					data-cy='select-enduser'
				/>
				{isMatRequisition && (
					<Select
						label={t('wms:SourceOwnership')}
						placeholder={t('wms:SourceOwnership')}
						value={
							inspectionType &&
							!inspectionType.is_modified_product &&
							selectedReport &&
							selectedReport.filters &&
							selectedReport.filters.source_ownership
								? selectedReport.filters.source_ownership
								: ownership
						}
						options={ownershipList.map(eu => ({ value: eu.id, label: eu.label }))}
						onChange={value => {
							setOwnership(value)
						}}
						width='100%'
						margin='8px 0 0 0'
						disabled={
							isReturn &&
							inspectionType &&
							!inspectionType.is_modified_product &&
							selectedReport &&
							selectedReport.filters &&
							selectedReport.filters.source_ownership
						}
						searchable
						data-cy='select-ownership'
					/>
				)}{' '}
				{isReturn && (
					<Select
						label={t('wms:TargetOwnership')}
						placeholder={t('wms:TargetOwnership')}
						value={
							inspectionType &&
							!inspectionType.is_modified_product &&
							selectedReport &&
							selectedReport.filters &&
							selectedReport.filters.target_ownership
								? selectedReport.filters.target_ownership
								: ownership
						}
						options={ownershipList.map(eu => ({ value: eu.id, label: eu.label }))}
						onChange={value => {
							setOwnership(value)
						}}
						width='100%'
						margin='8px 0 0 0'
						disabled={
							inspectionType &&
							!inspectionType.is_modified_product &&
							selectedReport &&
							selectedReport.filters &&
							selectedReport.filters.target_ownership
						}
						searchable
						data-cy='select-ownership'
					/>
				)}
				{isArrival && (
					<Select
						label={materialStr}
						placeholder={materialStr}
						value={material}
						options={materials && materials.map(eu => ({ value: eu.id, label: eu.formatted }))}
						onChange={value => {
							selectMaterial(value)
							setMaterial(value)
						}}
						width='100%'
						margin='8px 0 0 0'
						searchable
						disabled={checkRules()}
						data-cy='select-material'
					/>
				)}
			</FlexView>
			{inspectionType && inspectionType.is_modified_product && (
				<>
					<FlexView width='100%' margin='0' padding='0' position='relative'>
						<Select
							label={t('wms:SourceMaterial')}
							placeholder={t('wms:SourceMaterial')}
							value={sourceMaterial}
							options={
								sourceMatList &&
								sourceMatList.map(mat => ({
									value: mat.id,
									label: mat.formatted
								}))
							}
							onChange={value => {
								selectMaterial(value)
								setSourceMaterial(value)
							}}
							width='100%'
							margin='8px 0 0 0'
							searchable
							data-cy='select-source-material'
						/>
					</FlexView>
					<FlexView width='100%' margin='0' padding='0' position='relative'>
						<Select
							label={t('wms:TargetMaterial')}
							placeholder={t('wms:TargetMaterial')}
							value={targetMaterial}
							options={
								targetMatList &&
								targetMatList.map(mat => ({
									value: mat.id,
									label: mat.formatted
								}))
							}
							onChange={value => setTargetMaterial(value)}
							width='100%'
							margin='8px 0 0 0'
							searchable
							data-cy='select-target-material'
						/>
					</FlexView>
				</>
			)}{' '}
			{isMatRequisition && (
				<FlexView width='100%' margin='0' padding='0' position='relative'>
					<Select
						label={materialStr}
						placeholder={materialStr}
						value={material}
						options={
							materials && materials.length && materials.map(eu => ({ value: eu.id, label: eu.formatted }))
						}
						onChange={value => {
							selectMaterial(value)
							setMaterial(value)
						}}
						width='100%'
						margin='8px 0 0 0'
						searchable
						disabled={checkRules()}
						data-cy='select-material'
					/>
					<Select
						label={t('wms:SourceRack')}
						placeholder={t('wms:SelectRack')}
						value={rack}
						options={shelfList && shelfList.map(eu => ({ value: eu.id, label: eu.fullname }))}
						onChange={value => {
							selectLevel(value)
							setRack(value)
						}}
						width='100%'
						margin='8px 0 0 0'
						searchable
						data-cy='select-rack'
					/>
				</FlexView>
			)}{' '}
			{isArrival && sourceType === 'CUSTOMER_ITEM' && (
				<FlexView width='100%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<Select
						label={t(`wms:CustomerOrder`)}
						placeholder={t(`wms:CustomerOrder`)}
						value={cOrder}
						options={customerOrderList.map(c => ({
							label: c.label,
							value: c.label
						}))}
						onChange={value => {
							setCOrder(value)
						}}
						width='100%'
						inline={false}
						margin='0 8px 0 0'
						searchable
						disabled={checkRules()}
						data-cy='select-customer-order'
					/>
					<Select
						label={t(`wms:CustomerItem`)}
						placeholder={t(`wms:CustomerItem`)}
						value={cItem}
						options={
							filteredPoItems &&
							filteredPoItems.map(p => ({
								label: p.label,
								value: p.label
							}))
						}
						onChange={value => {
							setCItem(value)
						}}
						width='100%'
						inline={false}
						margin='0'
						disabled={checkRules()}
						searchable
						data-cy='select-customer-item'
					/>
				</FlexView>
			)}
			{isArrival && sourceType === 'SALES_ITEM' && (
				<FlexView width='100%' margin='0 0 16px 0' padding='0' position='relative' flexDirection='column'>
					<Select
						label={t(`wms:SalesOrder`)}
						placeholder={t(`wms:SalesOrder`)}
						value={sOrder}
						options={salesOrderList.map(value => ({
							label: value.label,
							value: value.label
						}))}
						onChange={value => {
							setSOrder(value)
						}}
						width='100%'
						inline={false}
						margin='0 8px 0 0'
						disabled={checkRules()}
						searchable
					/>
					<Select
						label={t(`wms:SalesItem`)}
						placeholder={t(`wms:SalesItem`)}
						value={sItem}
						options={
							filteredSalesItems &&
							filteredSalesItems.map(s => ({
								label: s.label,
								value: s.label
							}))
						}
						onChange={value => {
							setSItem(value)
						}}
						width='100%'
						inline={false}
						disabled={checkRules()}
						margin='0'
					/>
				</FlexView>
			)}
			{inspectionType && !isRigReturn() && (
				<FlexView width='100%' margin='0' padding='0' position='relative'>
					<LoadingOverlay visible={loadingQty} />
					{inspectionType.is_modified_product && (
						<Input
							label={t('wms:ExpectedQuantity')}
							placeholder={`${qntAvailable - sumByProp(newData.contents, 'quantity')}`}
							margin='8px 0 0 0'
							min='1'
							max={'99999'}
							type='number'
							value={quantity}
							onChange={e => {
								setQuantity(toInteger(e.target.value))
							}}
							fontSize='small'
							width='100%'
							disabled={targetMaterial === null}
						/>
					)}
					{!inspectionType.is_modified_product && (
						<Input
							label={`${t('wms:Quantity')} ${
								`[${t('wms:AvailablePipes')}: ${qntAvailable - sumByProp(newData.contents, 'quantity')}]` || ''
							}`}
							placeholder={`${qntAvailable - sumByProp(newData.contents, 'quantity')}`}
							margin='8px 0 0 0'
							min='1'
							max={qntAvailable - sumByProp(newData.contents, 'quantity')}
							type='number'
							value={quantity}
							onChange={e => {
								setQuantity(toInteger(e.target.value))
							}}
							success={quantity < qntAvailable ? true : false}
							error={quantity > qntAvailable ? true : false}
							fontSize='small'
							width='100%'
							disabled={qntAvailable === 0}
						/>
					)}
				</FlexView>
			)}
			{inspectionType && checkButtonRules() && (
				<FlexView width='100%' margin='auto 0 0 0' alignItems='center'>
					<Button
						fontSize='medium'
						margin='0'
						color='white'
						backgroundColor='secondary'
						disabled={checkFilters()}
						onClick={() => {
							addContent()
						}}>
						{t('wms:AddPart')}
						<Icon name='add' width='12px' height='10px' color='white' margin='0 0 0 8px' />
					</Button>
				</FlexView>
			)}
			{inspectionType && !checkButtonRules() && (
				<FlexView width='100%' margin='auto 0 0 0' alignItems='center'>
					<Button
						fontSize='medium'
						margin='0'
						color='white'
						backgroundColor='warning'
						disabled={allNull()}
						onClick={() => resetFields()}
						data-cy='button-reset'>
						{t('wms:Restart')}
						<Icon name='refresh' color='white' height='16px' width='16px' margin='0 0 0 8px' />
					</Button>
				</FlexView>
			)}
		</FlexView>
	)
}

export default Filters
