/** @format */

import React, { useState, useContext, useEffect, useCallback } from 'react'
import { UserContext } from 'stores/UserStore'
import content from '../api/content'
import { bundleOrItems } from '../utils/warehouse'
import { WMSContext } from './WmsStore'
import common from '../api/common'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { INSPECTION_STAGES, REPORT_TYPE, STOCK_STATUS } from '../utils/enum'
import moment from 'moment'
import usageRights from '../api/usageRights'
import reports from '../api/reports'

const initialState = {
	attributeDescriptions: []
}

export const ContentContext = React.createContext(initialState)

export const ContentProvider = ({ children }) => {
	const { t, i18n } = useTranslation()
	const { token } = useContext(UserContext)
	const {
		warehouse,
		lengthUnit,
		weightUnit,
		convertToCurrentLengthUnit,
		displayBundles,
		getEnduserList,
		getPrinters,
		diameterUnit,
		convertToCurrentDiameterUnit
	} = useContext(WMSContext)
	const [options, setOptions] = useState([])
	const [searchMaterial, setSearchMaterial] = useState([])
	const [pageLoading, setPageLoading] = useState(true)
	const [attributeDescriptions, setAttributeDescriptions] = useState([])
	const [materialDescription, setMaterialDescription] = useState([])
	const [currentTab, setCurrentTab] = useState(0)
	const [searchValid, setSearchValid] = useState('')
	const [searchPuuid, setSearchPuuid] = useState('')
	const [searchItems, setSearchItems] = useState(false)
	const [result, setResult] = useState(null)
	const [filteredMvid, setFilteredMvid] = useState([])
	const [statusDash, setStatusDash] = useState([])
	const [total, setTotal] = useState(0)
	const [materials, setMaterials] = useState([])
	const [material, setMaterial] = useState(undefined)
	const [attribute, setAttribute] = useState([])
	const [materialSelected, setMaterialSelected] = useState(null)
	const [minLength, setMinLength] = useState(undefined)
	const [maxLength, setMaxLength] = useState(undefined)
	const [erpReference, setErpReference] = useState([])
	const [erpResult, setErpResult] = useState(undefined)
	const [response, setResponse] = useState(null)
	const [foundMaterial, setFoundMaterial] = useState([])
	const [showTable, setShowTable] = useState(false)
	const [search, setSearch] = useState(false)
	const [resultOwnership, setResultOwnership] = useState([])
	const [responseOwnership, setResponseOwnership] = useState(null)
	const [searchingByOwnership, setSearchingByOwnership] = useState(false)
	const [levelId, setLevelId] = useState(null)
	const [levelContent, setLevelContent] = useState(false)
	const [mId, setMid] = useState([])
	const [pipe, setPipe] = useState(null)
	const [openPipeData, setOpenPipeData] = useState(false)
	const [pipeData, setPipeData] = useState(null)
	const [printers, setPrinters] = useState(null)
	const [allPrinters, setAllPrinters] = useState(false)
	const [printerSelected, setPrinterSelected] = useState(null)
	const [exportExcel, setExportExcel] = useState(false)
	const [exportItemsDash, setExportItemsDash] = useState(false)
	const [loadingMatFilter, setLoadingMatFilter] = useState(false)
	const [loadingTable, setLoadingTable] = useState(false)
	const [filters, setFilters] = useState({})
	const [tableData, setTableData] = useState([])
	const [downloading, setDownloading] = useState(false)
	const [fullLevelsList, setFullLevelsList] = useState([])
	const [endUsers, setEndUsers] = useState([])
	const [endUserSelected, setEndUserSelected] = useState(null)
	const [filteredMaterials, setFilteredMaterials] = useState([])
	const [loadingItems, setLoadingItems] = useState(false)
	const [levelsName, setLevelsName] = useState([])
	const [subLevels, setSubLevels] = useState([])
	const [firstLevel, setFirstLevel] = useState({})
	const [lastOwnership, setLastOwnership] = useState(null)
	const [filteredLevels, setFilteredLevels] = useState([])
	const [state, setState] = useState({})
	const [isOpen, setIsOpen] = useState(false)
	const [items, setItems] = useState([])
	const [myPipeLastStatus, setMyPipeLastStatus] = useState('')
	const [rack, setRack] = useState(null)
	const [nominalDataFields, setNominalDataFields] = useState([])
	const [actualDataFields, setActualDataFields] = useState([])
	const [actualDataSelected, setActualDataSelected] = useState({})
	const [nominalDataSelected, setNominalDataSelected] = useState({})
	const [filterByNominal, setFilterByNominal] = useState(true)
	const [filteredContentList, setFilteredContentList] = useState([])
	const [showModalFilter, setShowModalFilter] = useState(false)
	const [actualDataList, setActualDataList] = useState([])
	const invalidItems = t('wms:InvalidItems')

	const getActualDataInfo = async () => {
		try {
			setPageLoading(true)
			const response = await content.getActualData(warehouse.id, token)
			const certificates = await content.getCertificates(warehouse.id, token)
			const customerorders = []
			const customeritems = []
			const salesorders = []
			const salesitems = []
			const heatnumbers = []
			const pipestatuses = []
			const consignmenttypes = []
			const certificatenumbers = []
			const certificateversions = []
			const noEmptyCertificates =
				certificates &&
				certificates.filter(cert => cert.certificatenumbers.length !== 0 && cert.certificateversions.length !== 0)
			noEmptyCertificates &&
				noEmptyCertificates.forEach(cert => {
					certificatenumbers.push(...cert.certificatenumbers.map(certnumber => certnumber))
					certificateversions.push(...cert.certificateversions.map(certversion => certversion))
				})
			const formattedResponse = response.map(res => {
				const data = {}
				data['consignmenttypes'] = res.storagetypes
				delete res.storagetypes
				return { ...data, ...res }
			})
			formattedResponse &&
				formattedResponse.forEach(actual => {
					customerorders.push(...actual.customerorders.map(corder => corder))
					customeritems.push(...actual.customeritems.map(citem => citem))
					salesorders.push(...actual.salesorders.filter(sorder => sorder != null).map(salesorder => salesorder))
					salesitems.push(...actual.salesitems.filter(sitem => sitem != null).map(salesitem => salesitem))
					heatnumbers.push(...actual.heatnumbers.filter(heatnumber => heatnumber !== '').map(heat => heat))
					pipestatuses.push(
						...actual.pipestatuses.filter(
							status =>
								status !== STOCK_STATUS.code.Delivered &&
								status !== STOCK_STATUS.code.Dispatched &&
								status !== STOCK_STATUS.code.Scrapped &&
								status !== STOCK_STATUS.code.WIPO
						)
					)
					consignmenttypes.push(...actual.consignmenttypes.map(storage => storage))
				})
			const actualDatas = {
				customerorders: [...new Set(customerorders)],
				customeritems: [...new Set(customeritems)],
				salesorders: [...new Set(salesorders)],
				salesitems: [...new Set(salesitems)],
				heatnumbers: [...new Set(heatnumbers)],
				pipestatuses: [...new Set(pipestatuses)],
				consignmenttypes: [...new Set(consignmenttypes)],
				certificatenumbers: [...new Set(certificatenumbers)],
				certificateversions: [...new Set(certificateversions)]
			}
			setActualDataFields(actualDatas)
			setActualDataList([...formattedResponse, ...noEmptyCertificates])
		} catch (e) {
			console.error(e)
			toast.error(`${t('wms:ErrorGettingActualData')}: ${e}`)
		} finally {
			setPageLoading(false)
		}
	}

	const getMaterials = () =>
		new Promise((resolve, reject) => {
			setLoadingMatFilter(true)
			setShowTable(false)
			common
				.getMaterials(warehouse.id, token)
				.then(response => {
					const matDescriptions = response.materialdescriptions
					const atrDescriptions = response.attributedescriptions
					const nominalDataDescriptions = atrDescriptions
						.concat('modifiedproductdesc', 'erpreference', 'ismodifiedproduct')
						.map(nominal => ({ accessor: nominal, label: nominal }))
					const formattedMaterials = matDescriptions.map(material => {
						const data = {}
						material.descriptions.forEach((mat, m) => {
							data[mat] = material.values[m]
						})
						data['ismodifiedproduct'] = material.ismodifiedproduct
						data['modifiedproductdesc'] = material.modifiedproductdesc
						data['mvid'] = material.mvid
						data['erpreference'] = material.erpreference
						return data
					})
					setNominalDataFields(formattedMaterials)
					setMaterials(matDescriptions)
					setResponse(response)
					setAttribute(nominalDataDescriptions)
					resolve()
					setLoadingMatFilter(false)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingMaterialDsc')}: ${e}`)
					setLoadingMatFilter(false)
				})
		})

	const resetFields = () => {
		setShowTable(false)
		setLoadingTable(false)
		setSearchItems(false)
		setFoundMaterial([])
		setSearchPuuid('')
	}

	const getItems = () =>
		new Promise((resolve, reject) => {
			if (searchValid) {
				content
					.getItemsByValid(warehouse.id, searchValid, token)
					.then(response => {
						if (response.length === 0) {
							resetFields()
							toast.error(invalidItems)
						} else {
							setRack(
								...response.map(res => ({
									id: res.level.id,
									fullname: res.level.fullname
								}))
							)
							setFoundMaterial(
								response.map(res => ({
									id: res.material.id,
									formatted: res.material.formatted,
									rackId: res.level.id,
									fullname: res.level.fullname,
									quantity: res.itemcount
								}))
							)
							resolve()
							const firstRack = response.find(res => res.level.id)
							getLevelContents(firstRack.level.id, [firstRack.material.id]).catch(e => console.error(e))
							setShowTable(true)
						}
					})
					.catch(e => {
						reject(e)
						toast.error(`${t('wms:ErrorGettingItems')} [ ${e.status} ]: ${e.data}`)
					})
			}
			if (searchPuuid) {
				content
					.getItemsByPuuid(warehouse.id, searchPuuid, token)
					.then(response => {
						if (response.length === 0) {
							resetFields()
							toast.error(invalidItems)
						} else {
							setRack(
								response.map(res => ({
									id: res.level.id,
									fullname: res.level.fullname
								}))
							)
							setFoundMaterial(
								response.map(res => ({
									id: res.material.id,
									formatted: res.material.formatted,
									rackId: res.level.id,
									fullname: res.level.fullname,
									quantity: res.itemcount
								}))
							)
							resolve()
							const firstRack = response.find(res => res.level.id)
							getLevelContents(firstRack.level.id, [firstRack.material.id]).catch(e => console.error(e))
							setShowTable(true)
						}
					})
					.catch(e => {
						toast.error(toast.error(`${t('wms:ErrorGettingItems')} [ ${e.status} ]: ${e.data}`))
						reject(e)
					})
			}
		})

	const generateDashboard = () =>
		new Promise((resolve, reject) => {
			content
				.generateDashaboard(warehouse.id, token)
				.then(response => {
					let total = 0
					setStatusDash(response)
					_.forEach(response, st => {
						total += st.quantity
					})
					setTotal(total)
					resolve()
				})
				.catch(e => {
					reject(e)
				})
		})

	const filterTableData = () => {
		return tableData.filter(item => {
			let match = true
			_.chain(filters)
				.pickBy(filter => filter.length > 0)
				.each((filter, key) => {
					let itemValue = _.get(item, key)
					match = match & _.isEqual(filter, itemValue)
				})
				.value()
			return match
		})
	}

	const convertLength = (minLength, maxLength) => {
		let convertedMinLength
		let convertedMaxLength
		if (!isNaN(minLength) || !isNaN(maxLength)) {
			convertedMinLength = parseFloat(convertToCurrentLengthUnit(minLength, lengthUnit))
			convertedMaxLength = parseFloat(convertToCurrentLengthUnit(maxLength, lengthUnit))
		}
		return [convertedMinLength, convertedMaxLength]
	}
	const getMaterialBySearch = () =>
		new Promise((resolve, reject) => {
			setLoadingTable(true)
			const convertedLenght = convertLength(minLength, maxLength)
			const convertedMinLength = convertedLenght[0]
			const convertedMaxLength = convertedLenght[1]
			let mvid = []
			let filteredTableData = filterTableData()
			if (material) {
				mvid.push(material)
			} else {
				mvid = filteredTableData.map(mat => mat.mvid)
			}
			content
				.getMaterialsBySearch(
					warehouse.id,
					{
						filtermaterialids: mvid,
						minlengthmm: convertedMinLength || null,
						maxlengthmm: convertedMaxLength || null
					},
					token
				)
				.then(response => {
					if (response.length === 0) {
						toast.error(invalidItems)
						setShowTable(false)
					} else {
						const materialsBySearch = []
						response.forEach(res => {
							const matchedMaterial = materials.find(mat => mat.mvid === res.material.id)
							const sameEndUser = res.material.enduserid === endUserSelected
							if (matchedMaterial || sameEndUser) {
								materialsBySearch.push({
									...matchedMaterial,
									rackId: res.level.id,
									fullname: res.level.fullname,
									quantity: res.itemcount
								})
							}
						})
						setFoundMaterial(materialsBySearch)
					}
					resolve()
					setLoadingTable(false)
				})
				.catch(e => {
					reject(e)
					setLoadingTable(false)
				})
				.finally(() => setLoadingTable(false))
		})

	const getOwnershipBySearch = ownershipId =>
		new Promise((resolve, reject) => {
			setSearchingByOwnership(true)
			ownershipId &&
				content
					.getOwnershipBySearch(warehouse.id, ownershipId, token)
					.then(response => {
						if (response.length === 0) {
							toast.error(invalidItems)
							setShowTable(false)
							setFoundMaterial([])
						} else {
							let materialResult = []
							response.forEach(res => {
								const materialFound = materials.find(material => material.mvid === res.material.id)
								materialFound &&
									materialResult.push({
										...materialFound,
										rackId: res.level.id,
										fullname: res.level.fullname,
										quantity: res.itemcount
									})
							})
							setFoundMaterial(materialResult)
							setShowTable(true)
							setLastOwnership(ownershipId)
						}
						resolve()
						setLoadingTable(false)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingItemsByOwnership')} [${e.status}]: ${e.data}`)
						setSearchingByOwnership(false)
						console.error(e)
						reject(e)
					})
					.finally(() => setSearchingByOwnership(false))
		})

	const _getCertificatesByRack = (wid, levelId, token) =>
		new Promise((resolve, reject) => {
			wid &&
				levelId &&
				token &&
				content
					.getCertificatesByLevel(wid, levelId, token)
					.then(response => {
						resolve(response)
					})
					.catch(e => {
						console.error(e)
						toast.error(`${t('wms:ErrorGettingCertificates')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
		})

	const handleLastStatus = (items, search) => {
		return items.find(item => item.valid === search)
	}

	const filterItems = (items, materialids, mid) => {
		let ownership
		return items
			.filter(e => {
				ownership = fullLevelsList.find(o => o.id === e.ownershipid)
				return (
					(!materialids || materialids.indexOf(e.material.id) > -1) &&
					(!mid || mid.indexOf(e.material.id) > -1) &&
					(!minLength || e.lengthmm >= minLength) &&
					(!maxLength || e.lengthmm <= maxLength)
				)
			})
			.map(item => ({
				...item,
				ownershipname: ownership.label || '*****'
			}))
	}

	const filterItemsByActualData = (items, materialids, mid, actualDataFilters) => {
		let foundItems = []
		let ownership
		const actualDataValues = Object.values(actualDataFilters)
		if (actualDataValues && actualDataValues.length > 0) {
			items.forEach(item => {
				const itemValues = Object.values(item)
				itemValues.forEach(value => {
					actualDataValues.indexOf(value) > -1 && foundItems.push(item)
				})
			})
		} else {
			foundItems = items
		}

		return foundItems
			.filter(e => {
				ownership = fullLevelsList.find(o => o.id === e.ownershipid)
				return (
					(!materialids || materialids.indexOf(e.material.id) > -1) &&
					(!mid || mid.indexOf(e.material.id) > -1) &&
					(!minLength || e.lengthmm >= minLength) &&
					(!maxLength || e.lengthmm <= maxLength)
				)
			})
			.map(item => ({
				...item,
				ownershipname: ownership.label || '*****'
			}))
	}

	const handleError = e => {
		console.error(e)
		throw e
	}

	const getLevelContents = (levelId, mid, ownership) =>
		new Promise((resolve, reject) => {
			setLoadingItems(true)
			_getCertificatesByRack(warehouse.id, levelId, token)
				.then(list => {
					list &&
						content
							.getLevelContents(warehouse.id, levelId, ownership, token)
							.then(response => {
								const materialids = materials.map(mat => mat.mvid)
								const items = bundleOrItems(warehouse, response, list)
								const foundItems = handleLastStatus(items, searchValid || searchPuuid)
								foundItems && setMyPipeLastStatus(foundItems.laststatus)
								if (filterByNominal) {
									const filteredItems = filterItems(items, materialids, mid)
									setItems(filteredItems)
									setLoadingItems(false)
								} else {
									const actualDataFilters = { ...actualDataSelected }
									if (Object.hasOwnProperty.call(actualDataFilters, 'pipestatuses')) {
										actualDataFilters.pipestatuses = STOCK_STATUS.literal[actualDataFilters.pipestatuses]
										setActualDataSelected({ ...actualDataSelected })
									}
									const foundItems = filterItemsByActualData(items, materialids, mid, actualDataFilters)
									foundItems && foundItems.length === 0 && toast.error(t('wms:NoPipesFound'))
									setItems(foundItems)
									setLoadingItems(false)
								}
								resolve()
							})
							.catch(e => {
								handleError(e)
								setLoadingItems(false)
							})
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					setLoadingItems(false)
				})
		})

	const formatOuterDiameter = response => {
		let number = response.pipedata.od
		if (number !== null && diameterUnit === 'in') {
			number = parseFloat(number).toFixed(3)
			let int
			let base = 128
			let numerator = number * base
			int = Math.floor(numerator / base)
			numerator = Math.floor(numerator - int * base)
			for (let i = 1; i <= numerator; i++) {
				if (Number.isInteger(numerator / i) && Number.isInteger(base / i)) {
					numerator = numerator / i
					base = base / i
					i = 1
				}
			}
			if (numerator) response.pipedata.od = `${int.toString()} ${numerator.toString()}/${base.toString()}} "`
			else response.pipedata.od = ''
		} else if (number !== null && diameterUnit === 'mm') {
			response.pipedata.od = convertToCurrentDiameterUnit(response.pipedata.od, diameterUnit)
		}
		return response
	}

	const getPipeData = () =>
		new Promise((resolve, reject) => {
			setPageLoading(true)
			common
				.getPipeData(warehouse.id, searchValid, searchPuuid, token)
				.then(response => {
					response.pipedata.actuallength = parseFloat(response.pipedata.actuallength).toFixed(3)
					response.pipedata.actualweight = parseFloat(response.pipedata.actualweight).toFixed(3)
					const formattedResponse = formatOuterDiameter(response)
					resolve(formattedResponse.pipedata)
					setPageLoading(false)
				})
				.catch(e => {
					reject(e)
					setPageLoading(false)
					setPipeData(null)
					toast.error(`${t('wms:ErrorGettingPipeData')} [ ${e.status} ]: ${e.data}`)
				})
				.finally(() => {
					setTimeout(() => {
						setPageLoading(false)
					}, '1500')
				})
		})

	const _getReportsFromValid = (wid, valid, token) =>
		new Promise((resolve, reject) => {
			Promise.allSettled([
				common.getWMSReportsFromValid(wid, valid, token),
				common.getInspectionReportsFromValid(wid, valid, token)
			])
				.then(([wmsReports, inspectorReports]) => {
					const response = []

					if (wmsReports.status === 'fulfilled')
						response.push(
							...wmsReports.value.map(r => ({
								...r,
								reportType: REPORT_TYPE.path.findIndex(p => p.slice(0, -1) === r.type)
							}))
						)

					if (inspectorReports.status === 'fulfilled')
						response.push(
							...inspectorReports.value.map(r => ({
								...r,
								stageLabel: INSPECTION_STAGES.translateKey(r.slug),
								isInspectorReport: true
							}))
						)

					resolve(response)
				})
				.catch(e => {
					toast.error(t(`${e.data}`))
					reject(e)
					toast.error(`${t('wms:ErrorGettingReports')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getReportsFromPuuid = useCallback(
		searchPuuid => {
			return _getReportsFromPuuid(warehouse.id, searchPuuid, token)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _getReportsFromPuuid = (wid, searchPuuid, token) =>
		new Promise((resolve, reject) => {
			common
				.getReportsFromPuuid(wid, searchPuuid, token)
				.then(response =>
					resolve(
						response.map(r => ({ ...r, reportType: REPORT_TYPE.path.findIndex(p => p.slice(0, -1) === r.type) }))
					)
				)
				.catch(e => {
					toast.error(t(`${e.data}`))
					reject(e)
				})
		})

	const getReportsFromValid = useCallback(
		valid => {
			return _getReportsFromValid(warehouse.id, valid, token)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, token]
	)

	const _downloadWMSReport = (wid, reportType, reportId, token) =>
		new Promise((resolve, reject) => {
			setDownloading(true)
			reportId !== null &&
				content
					.downloadReport(REPORT_TYPE.reportDownloadURL[reportType](wid, reportId), token)
					.then(response => {
						const link = document.createElement('a')
						link.href = response
						link.target = '_blank'
						link.click()
						resolve(true)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(
							`${t('wms:ErrorDownloadingReport')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`
						)
						setDownloading(false)
					})
					.finally(() => setDownloading(false))
		})

	const _downloadInspectorReport = (wid, reportType, reportId, part, token) =>
		new Promise((resolve, reject) => {
			setDownloading(true)
			reportId !== null &&
				reports
					.downloadInspReports(wid, reportId, reportType, part, token)
					.then(response => {
						const link = document.createElement('a')
						link.href = response
						link.target = '_blank'
						link.click()
						resolve(true)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(
							`${t('wms:ErrorDownloadingReport')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`
						)
						setDownloading(false)
					})
					.finally(() => setDownloading(false))
		})

	const downloadReport = useCallback(
		(reportType, reportId, isInspectorReport, part) =>
			isInspectorReport
				? _downloadInspectorReport(warehouse.id, reportType, reportId, part, token)
				: _downloadWMSReport(warehouse.id, reportType, reportId, token),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[warehouse, i18n, token]
	)

	const _printItems = (items, printer, token, t, displayBundles) =>
		new Promise((resolve, reject) => {
			if (displayBundles()) {
				let payload = {
					printer: printer,
					localids: [...items.map(it => it.localid)]
				}
				common
					.setPrintersByBundles(payload, token)
					.then(response => {
						resolve(response)
						toast.success(t('wms:Printing OK'))
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorPrinting')} [${e.status}]: ${e.data}`)
					})
			} else {
				let payload = {
					printer: printer,
					items: [...items]
				}
				common
					.setPrintersByItems(payload, token)
					.then(response => {
						resolve(response)
						toast.success(t('wms:Printing OK'))
						setPrinterSelected(null)
					})
					.catch(e => {
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorPrinting')} [${e.status}]: ${e.data}`)
						setPrinterSelected(null)
					})
			}
		})

	const printItems = useCallback(
		(items, printer) => _printItems(items, printer, token, t, displayBundles),
		[token, t, displayBundles]
	)

	const exportToExcel = () =>
		new Promise((resolve, reject) => {
			setDownloading(true)
			content
				.exportExcel(
					warehouse.id,
					levelId,
					0,
					i18n.translator.language,
					lengthUnit === 'mm' ? 'meter' : 'feet',
					weightUnit === 'kg' ? 'kilogram' : 'pound',
					{
						filtermaterialids: mId,
						minlengthmm: !isNaN(parseFloat(minLength)) ? convertToCurrentLengthUnit(parseFloat(minLength)) : null,
						maxlengthmm: !isNaN(parseFloat(maxLength)) ? convertToCurrentLengthUnit(parseFloat(maxLength)) : null,
						phystransissued: false
					},
					token
				)
				.then(response => {
					content
						.downloadLevelContent(warehouse.id, response.data.local, response.data.filename, token)
						.then(res => {
							let fileName = `Rack Content Extract - ${response.data.local} (${moment(new Date()).format(
								'M-DD-YYYY'
							)}).xlsx`
							let a = document.createElement('a')
							let file = new Blob([res], {
								type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
							})
							let fileUrl = window.URL.createObjectURL(file)
							a.href = fileUrl
							a.target = '_blank'
							a.download = fileName
							a.click()
							resolve(true)
						})
				})
				.catch(e => {
					toast.error(
						`${t('wms:ErrorDownloadingRackContent')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`
					)
					reject(e)
					setDownloading(false)
					toast.error(`${t('wms:ErrorExportingExcel')} [${e.status}]: ${e.data}`)
				})
		})

	const exportDashboard = () =>
		new Promise((resolve, reject) => {
			content
				.exportItems(
					warehouse.id,
					i18n.language,
					lengthUnit === 'mm' ? 'meter' : 'feet',
					weightUnit === 'kg' ? 'kilogram' : 'pound',
					token
				)
				.then(response => {
					resolve(response)
				})
				.catch(e => {
					toast.error(
						`${t('wms:ErrorExportingDashboard')} [ ${e.status} ]: ${e.statusText ? e.statusText : e.data}`
					)
					reject(e)
				})
		})

	const downloadCertificate = () =>
		new Promise((resolve, reject) => {
			let req = { path: pipeData.certificatepath }
			content
				.downloadCertificate(req, token)
				.then(response => {
					const link = document.createElement('a')
					link.href = response
					link.target = '_blank'
					link.click()
					resolve(true)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorDownloadCertificate')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getOwnershipLevels = () =>
		new Promise((resolve, reject) => {
			usageRights
				.getOwnershipLevels(warehouse.id, token)
				.then(response => {
					const levelsWithParents = _.map(response, (res, index) => ({
						...res,
						level: index + 1
					}))
					getOwnershipList(levelsWithParents)
					resolve()
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingOwnerships')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getOwnershipList = ownershipLevels =>
		new Promise((resolve, reject) => {
			ownershipLevels &&
				usageRights
					.getOwnershipsList(warehouse.id, token)
					.then(response => {
						let ownerDesc = response.ownershipdescriptions
						let subOwnerLevels = []
						let otherLevels = _.filter(ownershipLevels, owner => owner.level > 1)
						let otherOwnerDescLevels = _.filter(ownerDesc, owner => owner.level > 1)
						let initialLevel = ownershipLevels
							.filter(owner => owner.level === 1)
							.map(lv => ({
								id: lv.id,
								description: lv.description,
								options: ownerDesc
									.filter(owd => owd.level === lv.id)
									.map(lv => ({
										id: lv.id,
										label: lv.namepath[0]
									})),
								selectedOption: -1,
								selectedLevel: 0,
								level: lv.level
							}))
						setFirstLevel(initialLevel[0])
						_.forEach(otherLevels, lv => {
							subOwnerLevels.push({
								id: lv.id,
								description: lv.description,
								options: otherOwnerDescLevels
									.filter(owl => owl.level === lv.id)
									.map(owner => {
										let idx = _.findIndex(owner.idpath, i => i === owner.id)
										return {
											id: owner.id,
											parentIds: _.filter(owner.idpath, id => id !== owner.id),
											label: owner.namepath[idx]
										}
									})
									.concat({ id: -1, parentIds: [], label: 'All' }),
								level: lv.level,
								parentLevel: lv.level - 1,
								selectedOption: -1,
								selectedLevel: 0
							})
						})
						let levelList = []
						_.forEach(ownerDesc, res => {
							res.level === 1
								? levelList.push({ id: res.idpath[0], label: res.namepath[0] })
								: _.forEach(res.idpath, (id, idx) => {
										levelList.push({ id: id, label: res.namepath[idx] })
								  })
						})
						setFullLevelsList(_.uniqBy(levelList, 'id').sort((a, b) => a.id - b.id))
						setSubLevels(subOwnerLevels)
						resolve()
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${t('wms:ErrorGettingOwnerships')} [ ${e.status} ]: ${e.data}`)
					})
		})

	const uniqueMaterials = (array, param1, param2) => {
		const uniqueItems = {}
		_.forEach(array, item => {
			const key = item[param1] + '-' + item[param2]
			uniqueItems[key] = item
		})
		return Object.values(uniqueItems)
	}

	const filterMaterialList = () => {
		let filteredMatList
		if (filterByNominal) {
			filteredMatList = foundMaterial.filter(nominal => {
				return Object.entries(filters).every(([prop, values]) => {
					let objectValues
					if (prop === 'ismodifiedproduct' || prop === 'modifiedproductdesc' || prop === 'erpreference') {
						objectValues = nominal[prop] || []
						return nominal[prop] === values
					} else {
						objectValues = nominal.values
						return objectValues.includes(values)
					}
				})
			})
		} else {
			const filteredMaterials = actualDataList.filter(actual => {
				return Object.entries(filters).every(([prop, values]) => {
					const objectValues = actual[prop] || []
					return objectValues.includes(values)
				})
			})
			filteredMatList = foundMaterial.filter(material => {
				return filteredMaterials.some(filtered => filtered.mvid === material.mvid)
			})
		}
		if (filteredMatList && filteredMatList.length === 0) {
			toast.warn(t('wms:MaterialsNotFound'))
			setFilteredContentList([])
		} else {
			filteredMatList = uniqueMaterials(filteredMatList, 'mvid', 'rackId')
			setFilteredContentList(filteredMatList)
		}
	}

	/*************************************************
	 *                USE EFFECT
	 **************************************************/

	useEffect(() => {
		filters && Object.keys(filters).length > 0 && filterMaterialList()
		filters && Object.keys(filters).length === 0 && setFilteredContentList([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters])

	useEffect(() => {
		const hydrate = async () => {
			try {
				await getOwnershipLevels()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		let mat = material && _.find(materials, m => m.mvid === material)
		let endUser = mat && _.find(endUsers, eu => eu.id === mat.enduserid)
		endUser && setEndUserSelected(endUser.id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [material])

	useEffect(() => {
		endUserSelected && setFilteredMaterials(_.filter(materials, mat => mat.enduserid === endUserSelected))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endUserSelected])

	useEffect(() => {
		getEnduserList()
			.then(list => setEndUsers(list))
			.catch(e => console.error(e))
		getPrinters()
			.then(list => setPrinters(list))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		setShowTable(false)
		setSearchValid('')
		setSearchPuuid('')
		setLastOwnership(null)
		setMaterial(null)
		setEndUserSelected(null)
		setFilteredLevels([])
		setPipeData(null)
	}, [warehouse, currentTab])

	useEffect(() => {
		if (erpResult) {
			let erp = _.find(erpReference, er => {
				return er.value === erpResult
			})
			let filteredMaterialsByRef = _.filter(materials, mat => {
				return mat.erpreference === erp.label
			})
			setMaterials(filteredMaterialsByRef)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [erpResult])

	useEffect(() => {
		getMaterials()
		getActualDataInfo()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (searchItems) {
			const hydrate = async () => {
				try {
					setLoadingTable(true)
					await getItems()
					let pipeData = await getPipeData()
					let reports = ''
					if (searchValid) {
						reports = await getReportsFromValid(searchValid)
					} else {
						reports = await getReportsFromPuuid(searchPuuid)
					}
					pipeData.reports = reports
					setPipeData(pipeData)
					setOpenPipeData(true)
					setIsOpen(true)
				} catch (e) {
					setLoadingTable(false)
					console.error(e)
					setSearchItems(false)
				} finally {
					setLoadingTable(false)
					setSearchItems(false)
					setSearchValid('')
					setSearchPuuid('')
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchPuuid, searchValid, searchItems])

	useEffect(() => {
		searchValid !== '' && setPipeData(null)
		searchPuuid !== '' && setPipeData(null)
	}, [searchValid, searchPuuid])

	useEffect(() => {
		const hydrate = async () => {
			try {
				await generateDashboard()
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (search) {
			const hydrate = async () => {
				try {
					setLoadingTable(true)
					await getMaterialBySearch()
				} catch (e) {
					setLoadingTable(false)
					console.error(e)
					setSearch(false)
				} finally {
					setLoadingTable(false)
					setSearch(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search])

	useEffect(() => {
		if (pipe) {
			const hydrate = async () => {
				try {
					setPageLoading(true)
					let pipeData = await getPipeData()
					const reports = await getReportsFromValid(pipe.valid)
					pipeData.reports = reports
					setPipeData(pipeData)
				} catch (e) {
					setPageLoading(false)
					toast.error(`${t('wms:ErrorGettingPipeData')} [${e.status}]: ${e.data}`)
					//setSearchPipeData(false)
					console.error(e)
				} finally {
					setPageLoading(false)
					//setSearchPipeData(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pipe])

	useEffect(() => {
		if (allPrinters) {
			const hydrate = async () => {
				try {
					setPageLoading(true)
					await getPrinters()
				} catch (e) {
					setPageLoading(false)
					setAllPrinters(false)
					console.error(e)
				} finally {
					setPageLoading(false)
					setAllPrinters(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allPrinters])

	useEffect(() => {
		if (exportExcel) {
			const hydrate = async () => {
				try {
					setDownloading(true)
					await exportToExcel()
				} catch (e) {
					setExportExcel(false)
					console.error(e)
					setDownloading(false)
				} finally {
					setExportExcel(false)
					setDownloading(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exportExcel])

	useEffect(() => {
		setFoundMaterial([])
		setShowTable(false)
	}, [warehouse, endUserSelected])

	return (
		<ContentContext.Provider
			value={{
				options,
				attributeDescriptions,
				materialDescription,
				pageLoading,
				currentTab,
				statusDash,
				total,
				searchMaterial,
				materials,
				attribute,
				result,
				materialSelected,
				minLength,
				maxLength,
				erpReference,
				response,
				showTable,
				foundMaterial,
				search,
				resultOwnership,
				responseOwnership,
				searchValid,
				searchPuuid,
				searchItems,
				levelContent,
				mId,
				pipeData,
				allPrinters,
				printers,
				exportExcel,
				printerSelected,
				material,
				openPipeData,
				setOpenPipeData,
				erpResult,
				setErpResult,
				setMaterial,
				setPrinterSelected,
				setExportExcel,
				setPrinters,
				setAllPrinters,
				setPipeData,
				pipe,
				setPipe,
				setMid,
				setLevelContent,
				setLevelId,
				setSearchItems,
				setSearchValid,
				setSearchPuuid,
				setResponseOwnership,
				setResultOwnership,
				setSearch,
				setFoundMaterial,
				setShowTable,
				setResponse,
				setErpReference,
				setMaxLength,
				setMinLength,
				setMaterialSelected,
				setResult,
				setAttribute,
				setMaterials,
				setSearchMaterial,
				setStatusDash,
				setTotal,
				setCurrentTab,
				setPageLoading,
				setAttributeDescriptions,
				setMaterialDescription,
				setOptions,
				getMaterials,
				getPipeData,
				filteredMvid,
				setFilteredMvid,
				exportItemsDash,
				setExportItemsDash,
				loadingMatFilter,
				setLoadingMatFilter,
				loadingTable,
				setLoadingTable,
				filters,
				setFilters,
				tableData,
				setTableData,
				downloading,
				setDownloading,
				downloadCertificate,
				fullLevelsList,
				setFullLevelsList,
				downloadReport,
				printItems,
				endUsers,
				setEndUsers,
				endUserSelected,
				setEndUserSelected,
				filteredMaterials,
				setFilteredMaterials,
				loadingItems,
				setLoadingItems,
				getLevelContents,
				exportDashboard,
				levelsName,
				setLevelsName,
				subLevels,
				setSubLevels,
				firstLevel,
				setFirstLevel,
				getOwnershipBySearch,
				searchingByOwnership,
				setSearchingByOwnership,
				lastOwnership,
				setLastOwnership,
				filteredLevels,
				setFilteredLevels,
				state,
				setState,
				setIsOpen,
				isOpen,
				items,
				setItems,
				myPipeLastStatus,
				rack,
				setRack,
				nominalDataFields,
				setNominalDataFields,
				actualDataFields,
				setActualDataFields,
				actualDataSelected,
				setActualDataSelected,
				getMaterialBySearch,
				filterByNominal,
				setFilterByNominal,
				filteredContentList,
				setFilteredContentList,
				showModalFilter,
				setShowModalFilter,
				actualDataList,
				setActualDataList,
				nominalDataSelected,
				setNominalDataSelected
			}}>
			{children}
		</ContentContext.Provider>
	)
}
